import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

// import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';


$(document).foundation();

var audio = {
  accessdenied: 'https://breakin.destrandhoeve.nl/assets/audio/accessdenied.mp3',
  accessgranted: 'https://breakin.destrandhoeve.nl/assets/audio/accessgranted.mp3',
  activating: 'https://breakin.destrandhoeve.nl/assets/audio/activating.mp3',
  beep: 'https://breakin.destrandhoeve.nl/assets/audio/beep.mp3',
  breach: 'https://breakin.destrandhoeve.nl/assets/audio/breach.mp3',
  caution: 'https://breakin.destrandhoeve.nl/assets/audio/caution.mp3',
  commsoffline: 'https://breakin.destrandhoeve.nl/assets/audio/commsoffline.mp3',
  communicationslost: 'https://breakin.destrandhoeve.nl/assets/audio/communicationslost.mp3',
  countdown: 'https://breakin.destrandhoeve.nl/assets/audio/countdown.mp3',
  criticaldata: 'https://breakin.destrandhoeve.nl/assets/audio/criticaldata.mp3',
  decrypting: 'https://breakin.destrandhoeve.nl/assets/audio/decrypting.mp3',
  deployarmed: 'https://breakin.destrandhoeve.nl/assets/audio/deployarmedpersonnel.mp3',
  deploysecurity: 'https://breakin.destrandhoeve.nl/assets/audio/deploysecurity.mp3',
  encrypting: 'https://breakin.destrandhoeve.nl/assets/audio/encrypting.mp3',
  exterminate: 'https://breakin.destrandhoeve.nl/assets/audio/exterminate.mp3',
  facilitybreach: 'https://breakin.destrandhoeve.nl/assets/audio/facilitybreach.mp3',
  goodbye: 'https://breakin.destrandhoeve.nl/assets/audio/goodbye.mp3',
  hawk: 'https://breakin.destrandhoeve.nl/assets/audio/hawk.mp3',
  keyboardlong: 'https://breakin.destrandhoeve.nl/assets/audio/keyboard.mp3',
  keyboard: 'https://breakin.destrandhoeve.nl/assets/audio/keyboard1.mp3',
  locating: 'https://breakin.destrandhoeve.nl/assets/audio/locating.mp3',
  nuclearcodes: 'https://breakin.destrandhoeve.nl/assets/audio/nuclearcodes.mp3',
  purge: 'https://breakin.destrandhoeve.nl/assets/audio/purge.mp3',
  purgefacility: 'https://breakin.destrandhoeve.nl/assets/audio/purge.mp3',
  searching: 'https://breakin.destrandhoeve.nl/assets/audio/searching.mp3',
  securitybreach: 'https://breakin.destrandhoeve.nl/assets/audio/securitybreach.mp3',
  securityclearance: 'https://breakin.destrandhoeve.nl/assets/audio/securityclearance.mp3',
  unauthorized: 'https://breakin.destrandhoeve.nl/assets/audio/unauthorized.mp3',
  uplink: 'https://breakin.destrandhoeve.nl/assets/audio/uplink.mp3',
  welcome: 'https://breakin.destrandhoeve.nl/assets/audio/welcome2.mp3'
}

// window.addEventListener("keyup", function (event) {
  // if (event.defaultPrevented) {
    // return; // Should do nothing if the default action has been cancelled
  // }

  // var handled = false;
	
  // if (event.key !== undefined) {
    // key = event.key;
		// type = 1;
		// handled = handleKeyEvent(key, type);
  // } else if (event.keyIdentifier !== undefined) {
    // key = event.keyIdentifier;
		// type = 2;
		// handled = handleKeyEvent(key, type);
  // } else if (event.keyCode !== undefined) {
    // key = event.keyCode;
		// type = 3;
		// handled = handleKeyEvent(key, type);
  // }
	
  // if (handled) {
    // /* Suppress "double action" if event handled */
    // event.preventDefault();
  // }
// }, true);

// function handleKeyEvent(key, type) {
	
	// return true;
// }

$(document).ready(function() {
	$('.lab-arrow').click(function() {
		if ($(this).hasClass('disabled')) {
			
		} else {
			var direction = $(this).data('direction');
			var cur_loc = $('#lab-center').data('curlocation');
			var start_loc = $('#lab-center').data('startlocation');
			var team = $('#team-nummer').val();
			
			$.ajax({
				type: 'POST',
				data: {
					team: team,
					direction: direction, 
					cur_loc: cur_loc
				},
				url: 'ajax_calls.php?action=lab_newloc',
				dataType: 'json',
				beforeSend: function() {
					$('.lab-arrow').prop('disabled', true).addClass('disabled');
					$('.code-return code').html('-- EVEN GEDULD --');
				},
				error: function() {
					$('.code-return code').html('-- NIET GEREGISTREERD --').addClass('flash');
					setTimeout(function() {
						$('.code-return code').html('-- WACHT OP INPUT --').removeClass('flash');
						$('.lab-arrow').prop('disabled', false).removeClass('disabled');
					}, 2000);
				},
				success: function(val_arr){
					if (val_arr.result == 'finish') {
						$('.lab-arrow').hide();
						var decryptingModal = $('#decryptingModal');
						$('#decrypting_img').attr('src', 'https://breakin.destrandhoeve.nl/assets/img/radar.gif');
						decryptingModal.foundation('open');
						$('#gen_audio').attr('src', audio['searching']);
						$('#gen_audio')[0].play();
						
						setTimeout(function() {
							$('#gen_audio').attr('src', audio['accessgranted']);
							$('#gen_audio')[0].play();
							setTimeout(function() {
								window.location.href = 'https://breakin.destrandhoeve.nl';
							}, 2000);
						}, 3000);
						
						
					} else if (val_arr.result == 'ok') {
						$('.code-return code').html('-- BEWEGING GEACCEPTEERD --').addClass('flash');
						$('#lab-center').addClass('flash');
						$('#gen_audio').attr('src', audio['activating']);
						$('#gen_audio')[0].play();
						setTimeout(function() {
							$('.code-return code').html('-- WACHT OP INPUT --').removeClass('flash');
							$('#lab-center').data('curlocation', val_arr.new_location);
							$('#lab-center').html(val_arr.new_location);
							$('#lab-center').removeClass('flash');
							$('.lab-arrow').prop('disabled', false).removeClass('disabled');
						}, 2000);
					} else {
						$('.code-return code').html('-- TOEGANG GEWEIGERD --').addClass('flash');
						$('#lab-center').addClass('flash');
						$('#lab-center').data('curlocation', start_loc);
						$('#gen_audio').attr('src', audio['accessdenied']);
						$('#gen_audio')[0].play();
						setTimeout(function() {
							$('.code-return code').html('-- WACHT OP INPUT --').removeClass('flash');
							$('#lab-center').removeClass('flash');
							$('#lab-center').html("<img src='https://breakin.destrandhoeve.nl/assets/img/big-arrow-start.png'/>");
							$('.lab-arrow').prop('disabled', false).removeClass('disabled');
						}, 2000);
					}
				}
			});
		}
			
	});
	
	
	
	
	$('.code-inputfield').keyup(function() {
		$('#gen_audio').attr('src', audio['keyboard']);
		$('#gen_audio')[0].play();
		$('.code-return code').html('-- vul de code in en druk op VERSTUREN --');
	});
	
	$('.send_button').click(function() {
		
		var id = $(this).attr('id');
		var level = id.split('_').pop();
		
		$.ajax({
			type: 'POST',
			data: {
				team: $('#team-nummer').val(),
				oid: $('#oid').val(),
				code: $('#code-inputfield').val()
			},
			url: 'ajax_calls.php?action=send_code',
			dataType: 'json',
			beforeSend: function() {
				$('.send_button').prop('disabled', true);
				$('#code-inputfield').prop('readonly', true);
				$('.send_button, #code-inputfield').addClass('disabled');
				$('.code-return code').html('-- EVEN GEDULD --');
			},
			success: function(val_arr){
				var dec_img = val_arr.decrypting_img;
				var dec_audio = val_arr.decrypting_audio;
				var dec_timeout = val_arr.decrypting_timeout;
				
				var decryptingModal = $('#decryptingModal');
				var timeout;
				
				$('#decrypting_img').attr('src', dec_img);
				decryptingModal.foundation('open');
				
				$('#gen_audio').attr('src', audio[dec_audio]);
				$('#gen_audio')[0].load();
				$('#gen_audio')[0].play();
				
				if (!val_arr.result) {
					dec_timeout = 4000;
				}
				
				// $('#audio_'+dec_audio)[0].play();
				setTimeout(function() {
					if (val_arr.result == true) {
						$('#gen_audio').attr('src', audio['accessgranted']);
						$('#gen_audio')[0].play();
						setTimeout(function() {
							window.location.href = 'https://breakin.destrandhoeve.nl';
						}, 2000);
					} else {
						$('#decrypting_img').attr('src', 'https://breakin.destrandhoeve.nl/assets/img/static.gif');
						setTimeout(function() {
							decryptingModal.foundation('close');
						}, 1000);
						$('.code-return code').html('-- TOEGANG GEWEIGERD --').addClass('flash');
						$('#gen_audio').attr('src', audio['accessdenied']);
						$('#gen_audio')[0].play();
						setTimeout(function() {
							$('.code-return code').html('-- VOER CODE IN --').removeClass('flash');
							$('.send_button').prop('disabled', false);
							$('#code-inputfield').prop('readonly', false);
							$('.send_button, #code-inputfield').removeClass('disabled');
						}, 2000);
					}
				}, dec_timeout);
			}
		});
		
	});
	
	$(document).on('click', '.hint-currency', function() {
		var hint = $(this).data('hint');
		$.ajax({
			type: 'POST',
			data: {
				team: $('#team-nummer').val(),
				oid: $('#oid').val(),
				hint: hint
			},
			url: 'ajax_calls.php?action=koop_hint',
			dataType: 'json',
			success: function(val_arr){
				$.ajax({
					type: 'POST',
					data: {
						team: $('#team-nummer').val(),
						oid: $('#oid').val()
					},
					url: 'ajax_calls.php?action=get_hints',
					dataType: 'json',
					success: function(val_arr){
						$('#hints_vak').html(val_arr.hints);
					}
				});
			}
		});
	});
	
	$.ajax({
		type: 'POST',
		data: {
			team: $('#team-nummer').val(),
			oid: $('#oid').val()
		},
		url: 'ajax_calls.php?action=get_hints',
		dataType: 'json',
		success: function(val_arr){
			$('#hints_vak').html(val_arr.hints);
		}
	});
	
	if ($('#reloadPixel').length){
		var interval = parseInt($('#reloadPixel').data('interval'));
		var curstatus = $('#reloadPixel').data('curstatus');
		var curendtime = $('#reloadPixel').data('curendtime');
		var uid = $('#reloadPixel').data('uid');
		console.log(interval +' '+ curstatus + ' '+ uid);
		setTimeout(function() {checkReload(interval, uid, curstatus, curendtime);}, interval);
	}
	
	
});

function checkReload(interval, uid, curstatus, curendtime) {
	$.ajax({
		type: 'POST',
		data: {
			curstatus: curstatus,
			uid: uid,
			curendtime: curendtime
		},
		url: 'ajax_calls.php?action=check_reload',
		dataType: 'json',
		timeout: 5000,
		success: function (val_arr) {
			console.log(val_arr);
			if (val_arr['result'] == 'reload') {
				location.reload();
			} 
		}, 
		complete: function (obj, str) {
			setTimeout(function() {checkReload(interval, uid, curstatus, curendtime);}, interval);
		}
	});
}